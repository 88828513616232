export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Infected Sprunki",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://iframegame.com/embed/sprunki-infected/index.html",
    domain: "infectedsprunki.com",
    gaId: "G-85V9FM15FZ",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
